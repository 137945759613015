import React from 'react';

import { type SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { Button } from 'components/common-n4/button';
import type { N4ErrorPageStoryblok } from 'types/storyblok-component-types';

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface ErrorPageProps {
  blok: N4ErrorPageStoryblok;
  story: Story;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ blok, story, ...props }) => {
  return (
    <section {...storyblokEditable(blok)}>
      {blok.button_link && (
        <Button.LinkPrimary target="_blank" href={blok.button_link.url}>
          {blok.button_text}
        </Button.LinkPrimary>
      )}
      {(blok.color_cards || []).map((b) => (
        <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />
      ))}
    </section>
  );
};

export default ErrorPage;
