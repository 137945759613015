import { storyblokEditable } from '@storyblok/react';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';

const log = logger({ category: 'n4/home/hero-lower-card' });

const HeroLowerCard = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  return (
    <section {...storyblokEditable(blok)} style={{ border: '1px solid yellow', margin: 40 }}>
      <h3>Hero Card</h3>
      <ul>
        <li>text: {blok.text}</li>
        <li>link: {blok.link.url}</li>
      </ul>
      <ImageWrapper image={blok.image} imgProps={{ style: { width: 200, height: 'auto' } }} origin="home-hero-lower-card" />
    </section>
  );
};

export default HeroLowerCard;
